import React from "react";

import portfolio2 from "../assect/images/logo/portfolio2.webp";

function et02() {
  return (
    <section id="our_portfolio_section">
      <div className="et02 menu_padding">
        <div className="hero_area">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column text-center">
                <h1 className="header-1 portfollio_hero_title">.</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row d-flex portfollio_header_img">
            <img src={portfolio2} className="img-191" alt="" />
          </div>
          <hr className="_line" />
        </div>

        <div className="body_section white_texture_bg body_section_phone">
          <div className="container pt-2rem pb-2rem">
            <div className="row d-flex align-items-center">
              <h2 className="header-3 portfollio_header_phone">
                Analytical Solutions
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  Our leading Analytical Tool in the world used by Intelligence,
                  Investigators, Military, Law Enforcement and Criminal
                  Analysts. This software’s ability to conduct “one click”
                  federated searches of unlimited databases provides analyst’s
                  the ability to rapidly acquire, ingest, assess, analyze, and
                  report intelligence that expands an organization’s ability to
                  become more predictive when assessing Critical Threats such as
                  Terrorism, Transnational Cyber-Attacks, Internal &amp;
                  External Threats, &amp; other External Risks. With connectors
                  built to over 25 technologies, our tool can connect users to
                  cutting edge technology products and databases to include
                  surface, deep, and dark web products along with exclusive
                  access to dozens of subscription databases. The solution is
                  100% customizable depending on the industry, (either Law
                  Enforcement, National Security/Defense, Healthcare, Banking,
                  Education or Government).
                </p>
                <ul className="et02_list">
                  <li>
                    <h1>
                      Perform complex searches with easy-to-use visual queries.
                    </h1>
                  </li>
                  <li>
                    <h1>
                      Process and ingest data faster than any solution on the
                      market.
                    </h1>
                  </li>
                  <li>
                    <h1>
                      Access to any data regardless of its location, data type,
                      or data structure.
                    </h1>
                  </li>
                </ul>
              </div>
            </div>

            <br></br>
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                Open-source intelligence (OSINT)
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description pt-6 portfollio_desc_phone">
                  Our solution is fully customized, commercial-off-the-shelf
                  (COTS) OSINT dashboard with built-in managed attribution
                  tools. Our OSINT tools that deliver easy to customize analytic
                  workflows and access to over a thousand OSINT datasets,
                  analytics tools, and resources. Our solution is highly
                  customizable and facilitates workflows for hundreds of OSINT
                  applications.
                </p>
                <ul className="et02_list">
                  <li>
                    <h1>
                      Federated searches of unlimited databases to  OSINT and customer data that is relevant
                    </h1>
                  </li>
                  <li>
                    <h1>
                    Extraction of OCR-ed text from the viewed image
                    </h1>
                  </li>
                </ul>
              </div>
            </div>

            <br></br>
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                Dark Web Solutions
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  Our tool DARKINT designed to search, monitor, and investigate content from current and historical darknet sites.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default et02;
