import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../assect/css/components.css";
import "../assect/css/responsive.css";
import { HashLink } from "react-router-hash-link";

import Logo from "../assect/images/logo/logo.png";
import menu from "../assect/images/icons/bars-solid.webp";
import cross from "../assect/images/icons/cross.webp";
// product portfolio
import dropdown1 from "../assect/images/logo/dropdown1.webp";
import dropdown2 from "../assect/images/logo/dropdown2.webp";
import dropdown3 from "../assect/images/logo/dropdown3.webp";
import dropdown4 from "../assect/images/logo/dropdown4.webp";
import dropdown5 from "../assect/images/logo/dropdown5.webp";
import rightArrow from "../assect/images/icons/right-arrow.webp";
import rightWhiteArrow from "../assect/images/icons/white-right-arrow.webp";

function Navbar() {
  const [showLinks, setShowLinks] = useState(false);

  return (
    <div className="container-fluid position-fixed mainmenu">
      <div className="row">
        <div className="navbar">
          <div className="left">
            <HashLink
              onClick={() => setShowLinks(false)}
              to="/#homepage_section"
            >
              <img className="main_logo" src={Logo} alt="logo"></img>
            </HashLink>
          </div>
          <div className="right">
            <div className="links">
              <Link
                to="#"
                onClick={() => setShowLinks(false)}
                className="main_menu common_link about_link"
                // activeClassName="active"
              >
                About
                <div className="dropdown_link about_menu flex-column">
                  <HashLink to="/about#about_page_section">
                    Our company
                  </HashLink>
                  <HashLink to="/capability#capability_page_section">
                    Capability Statement
                  </HashLink>
                  <HashLink to="/career#career_page_section">Career</HashLink>
                  <HashLink to="/customeraffiliation#customer_affiliation_section">
                    Customer & affiliation
                  </HashLink>
                </div>
              </Link>
              <Link
                to="#"
                onClick={() => setShowLinks(false)}
                className="main_menu solution"
                // activeClassName="active"
              >
                Solutions & Products
                <div className="solution_dropdown">
                  <div className="single_list">
                    <HashLink
                      to="/et01#our_portfolio_section"
                      className="dropdown_link_1"
                    >
                      <img src={dropdown1} className="img-191" alt="" />
                      <div className="d-flex">
                        <div className="bottom_dropdown bottom_dropdown_bg1">
                          <p className="d-flex">
                            <img
                              className="black_arrow"
                              src={rightArrow}
                              alt="arrow"
                            />
                            <img
                              className="white_arrow"
                              src={rightWhiteArrow}
                              alt="arrow"
                            />
                            <p>
                              Shielding & Advanced
                              <br />
                              Threat Detection
                            </p>
                          </p>
                        </div>
                        <div className="link_line line-left1"></div>
                      </div>
                    </HashLink>
                  </div>
                  <div className="single_list">
                    <HashLink
                      to="/et02#our_portfolio_section"
                      className="dropdown_link_3"
                    >
                      <img src={dropdown2} alt="" />
                      <div className="d-flex">
                        <div className="bottom_dropdown bottom_dropdown_bg3">
                          <p className="d-flex">
                            <img
                              className="black_arrow"
                              src={rightArrow}
                              alt="arrow"
                            />
                            <img
                              className="white_arrow"
                              src={rightWhiteArrow}
                              alt="arrow"
                            />
                            <p>Analytical Solution</p>
                          </p>
                          <p className="d-flex">
                            <img
                              className="black_arrow"
                              src={rightArrow}
                              alt="arrow"
                            />
                            <img
                              className="white_arrow"
                              src={rightWhiteArrow}
                              alt="arrow"
                            />
                            <p>Open-Source Intelligence (OSINT)</p>
                          </p>
                          <p className="d-flex">
                            <img
                              className="black_arrow"
                              src={rightArrow}
                              alt="arrow"
                            />
                            <img
                              className="white_arrow"
                              src={rightWhiteArrow}
                              alt="arrow"
                            />
                            <p>Dark Web Solution</p>
                          </p>
                        </div>
                        <div className="link_line line-left3"></div>
                      </div>
                    </HashLink>
                  </div>
                  <div className="single_list">
                    <HashLink
                      to="/et03#our_portfolio_section"
                      className="dropdown_link_4"
                    >
                      <img src={dropdown3} alt="" />
                      <div className="d-flex">
                        <div className="bottom_dropdown bottom_dropdown_bg4">
                          <p className="d-flex">
                            <img
                              className="black_arrow"
                              src={rightArrow}
                              alt="arrow"
                            />
                            <img
                              className="white_arrow"
                              src={rightWhiteArrow}
                              alt="arrow"
                            />
                            <p>
                              Network Monitoring
                              <br />
                              System
                            </p>
                          </p>
                        </div>
                        <div className="link_line line-left4"></div>
                      </div>
                    </HashLink>
                  </div>
                  <div className="single_list">
                    <HashLink
                      to="/ee01#our_portfolio_section"
                      className="dropdown_link_5"
                    >
                      <img src={dropdown4} alt="" />
                      <div className="d-flex">
                        <div className="bottom_dropdown bottom_dropdown_bg5">
                          {/* <HashLink to="/ee01#1" className="dropdown_link_5"> */}
                          <p className="d-flex">
                            <img
                              className="black_arrow"
                              src={rightArrow}
                              alt="arrow"
                            />
                            <img
                              className="white_arrow"
                              src={rightWhiteArrow}
                              alt="arrow"
                            />
                            <p>
                              Secure Mobile Device
                              <br />
                              Communication
                            </p>
                          </p>
                          {/* </HashLink>   */}
                          <p className="d-flex">
                            <img
                              className="black_arrow"
                              src={rightArrow}
                              alt="arrow"
                            />
                            <img
                              className="white_arrow"
                              src={rightWhiteArrow}
                              alt="arrow"
                            />
                            <p>Deception as a Defense</p>
                          </p>
                          <p className="d-flex">
                            <img
                              className="black_arrow"
                              src={rightArrow}
                              alt="arrow"
                            />
                            <img
                              className="white_arrow"
                              src={rightWhiteArrow}
                              alt="arrow"
                            />
                            <p>Build Custom Cyber Labs</p>
                          </p>
                        </div>
                        <div className="link_line"></div>
                      </div>
                    </HashLink>
                  </div>
                  <div className="single_list">
                    <HashLink
                      // to="/ee00#our_portfolio_section"
                      className="dropdown_link_5"
                    >
                      <img src={dropdown5} alt="" />
                      <div className="d-flex">
                        <div className="bottom_dropdown bottom_dropdown_bg5">
                          {/* <HashLink to="/ee01#1" className="dropdown_link_5"> */}
                          <p className="d-flex">
                            <img
                              className="black_arrow"
                              src={rightArrow}
                              alt="arrow"
                            />
                            <img
                              className="white_arrow"
                              src={rightWhiteArrow}
                              alt="arrow"
                            />
                            <p>
                            Machineries & Equipment
                            </p>
                            </p>
                        </div>
                        <div className="link_line"></div>
                      </div>
                    </HashLink>
                  </div>
                </div>
              </Link>

              <Link
                to="#"
                onClick={() => setShowLinks(false)}
                className="main_menu common_link services_link"
                // activeClassName="active"
              >
                Services & Training
                <div className="dropdown_link services_menu  flex-column">
                  <HashLink to="/services#services_trining_page_section">
                    services
                  </HashLink>
                  <HashLink to="/training#trining_page_section">
                    Traning
                  </HashLink>
                  {/* <Link>Traning</Link> */}
                </div>
              </Link>
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/partners#partner_page_section"
                className="main_menu common_link"
              >
                Partners
              </HashLink>
              <Link
                to="#"
                onClick={() => setShowLinks(false)}
                className="main_menu common_link resources_link"
              >
                Resources
                <div className="dropdown_link resources_menu  flex-column">
                  <HashLink to="/quicklink#quicklink_section">
                    Quick Link
                  </HashLink>
                  <HashLink to="/underconstruction#underconstruction_section">
                    Use cases
                  </HashLink>
                  <HashLink
                    to="/underconstruction#underconstruction_section"
                    className="eBOOK"
                  >
                    eBOOK
                  </HashLink>
                  {/* <HashLink to="/use-case#usecase_page_section">Video</HashLink> */}
                </div>
              </Link>
              <Link
                to="#"
                onClick={() => setShowLinks(false)}
                className="main_menu common_link news_event_link"
              >
                News & Events
                <div className="dropdown_link event_menu  flex-column">
                  <HashLink to="/underconstruction#underconstruction_section">
                    Our Activities
                  </HashLink>
                  <HashLink to="/underconstruction#underconstruction_section">
                    Latest news
                  </HashLink>
                  <HashLink to="/underconstruction#underconstruction_section">
                    Event
                  </HashLink>
                </div>
              </Link>
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/contact#contact_section"
                className="main_menu common_link"
              >
                Contact Us
              </HashLink>
            </div>
            <button
              className="toggleNav"
              onClick={() => setShowLinks(!showLinks)}
            >
              {showLinks ? (
                <img src={cross} alt="menu" />
              ) : (
                <img src={menu} alt="cross" />
              )}
            </button>
          </div>
        </div>
        <div></div>
        <div className="mobile_nav" id={showLinks ? "hidden" : "notHidden"}>
          <Link to="#" className="mobile_common_links">
            About
            <div className="mobile_dropdown_link">
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/about#about_page_section"
              >
                Our company
              </HashLink>
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/capability#capability_page_section"
              >
                Capability Statement
              </HashLink>
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/career#career_page_section"
              >
                Career
              </HashLink>
              <HashLink
                className="pb_0"
                onClick={() => setShowLinks(false)}
                to="/customeraffiliation#customer_affiliation_section"
              >
                Customer & affiliation
              </HashLink>
            </div>
          </Link>
          <Link to="#" className="mobile_common_links">
            Solutions & Products
            <div className="mobile_dropdown_link">
              <Link to="#" className="mobile_solution_dropdown">
                ET 01
                <div className="mobile_solution_link">
                  <HashLink
                    className="pb_0"
                    onClick={() => setShowLinks(false)}
                    to="/et01#our_portfolio_section"
                  >
                    Shielding & Advanced Threat Detection
                  </HashLink>
                </div>
              </Link>
              <Link to="#" className="mobile_solution_dropdown">
                ET 02
                <div className="mobile_solution_link">
                  <HashLink
                    onClick={() => setShowLinks(false)}
                    to="/et02#our_portfolio_section"
                  >
                    Analytical Solution
                  </HashLink>
                  <HashLink
                    onClick={() => setShowLinks(false)}
                    to="/et02#our_portfolio_section"
                  >
                    Open-Source Intelligence (OSINT)
                  </HashLink>
                  <HashLink
                    className="pb_0"
                    onClick={() => setShowLinks(false)}
                    to="/et02#our_portfolio_section"
                  >
                    Dark Web Solution
                  </HashLink>
                </div>
              </Link>
              <Link to="#" className="mobile_solution_dropdown">
                ET 03
                <div className="mobile_solution_link">
                  <HashLink
                    onClick={() => setShowLinks(false)}
                    to="/et03#our_portfolio_section"
                  >
                    Network Monitoring System
                  </HashLink>
                </div>
              </Link>
              <Link to="#" className="mobile_solution_dropdown">
                EE 01
                <div className="mobile_solution_link">
                  <HashLink
                    onClick={() => setShowLinks(false)}
                    to="/ee01#our_portfolio_section"
                  >
                    Secure Mobile Device Communication
                  </HashLink>
                  <HashLink
                    onClick={() => setShowLinks(false)}
                    to="/ee01#our_portfolio_section"
                  >
                    Deception as a Defense
                  </HashLink>
                  <HashLink
                    className="pb_0"
                    onClick={() => setShowLinks(false)}
                    to="/ee01#our_portfolio_section"
                  >
                    Build Custom Cyber Labs
                  </HashLink>
                </div>
              </Link>
            </div>
          </Link>

          <Link to="#" className="mobile_common_links">
            Services & Training
            <div className="mobile_dropdown_link">
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/services#services_trining_page_section"
              >
                services
              </HashLink>
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/training#trining_page_section"
                className="pb_0"
              >
                Traning
              </HashLink>
            </div>
          </Link>
          <HashLink
            onClick={() => setShowLinks(false)}
            to="/partners#partner_page_section"
            className="mobile_common_links"
          >
            Partners
          </HashLink>
          <Link to="#" className="mobile_common_links">
            Resources
            <div className="mobile_dropdown_link">
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/quicklink#quicklink_section"
              >
                Quick Link
              </HashLink>
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/underconstruction#underconstruction_section"
              >
                Use cases
              </HashLink>
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/underconstruction#underconstruction_section"
                className="eBOOK"
              >
                eBOOK
              </HashLink>
              {/* <HashLink className="pb_0" onClick={() => setShowLinks(false)}  to="/use-case#usecase_page_section">Video</HashLink> */}
            </div>
          </Link>
          <Link to="#" className="mobile_common_links">
            News & Events
            <div className="mobile_dropdown_link">
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/underconstruction#underconstruction_section"
              >
                Our Activities
              </HashLink>
              <HashLink
                onClick={() => setShowLinks(false)}
                to="/underconstruction#underconstruction_section"
              >
                Latest news
              </HashLink>
              <HashLink
                className="pb_0"
                onClick={() => setShowLinks(false)}
                to="/underconstruction#underconstruction_section"
              >
                Event
              </HashLink>
            </div>
          </Link>
          <HashLink
            onClick={() => setShowLinks(false)}
            to="/contact#contact_section"
            className="mobile_common_links"
          >
            Contact Us
          </HashLink>
          <div
            onClick={() => setShowLinks(false)}
            className="dropdown_bottom_part"
          ></div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
