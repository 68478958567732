import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import partnerLogo from '../assect/images/partners/partner-logo-6.webp';
import binary from '../assect/images/partners/binary.webp';
import ampex from "../assect/images/partners/ampex.webp";
import aws from "../assect/images/partners/aws.webp";
import micro from "../assect/images/partners/micro.webp";
import ibm from "../assect/images/partners/ibm.webp";
import blueLight from "../assect/images/partners/bluelight.webp";

function Login() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <section id="partner_page_section">
      <div class="partners_sec menu_padding">
        <div class="container">
          <div class="row hero_title">
            <div class="title d-flex flex-column m-auto ">
              <h1 className="header-1 mt-2rem">
                <span>Our</span> Partners
              </h1>
            </div>
          </div>
          <div class="row description pt-2rem pb-4rem text-justify pl-2 pr-2">
            <p data-aos="zoom-in">
              Finding the right partners to execute your vision can be
              challenging and demanding. Enrich Group understands what it takes
              for its clients and partners to thrive in today’s digital era.
              With us at your side you can further grow your business and secure
              your systems with exclusive tools and benefits.
            </p>
          </div>
          <div className="row partner_logo mt-4rem pt-4rem partner_top_logo">
            <div className="col-md-1"></div>
            <div
              data-aos="zoom-in"
              className="col-md-4 text-right partner_single_logo"
            >
              <img className="ibm mr-4" src={ibm} alt="" />
            </div>
            <div
              data-aos="zoom-in"
              className="col-md-3 text-center mt-3 partner_single_logo"
            >
              <img className="aws mt-4" src={aws} alt="" />
            </div>
            <div
              data-aos="zoom-in"
              className="col-md-3 text-left mt-3 partner_single_logo"
            >
              <img className="ampex ml-3 mt-4" src={ampex} alt="" />
            </div>
            <div className="col-md-1"></div>
          </div>
          <div className="row partner_logo mt-4rem partner_logo_bottom">
            {/* <div className="col-md-3"></div>

            <div
              data-aos="zoom-in"
              className="col-md-3 text-center mt-4 partner_single_logo"
            >
              <img className="mt-2 ml-5 ampex" src={blueLight} alt="" />
            </div>
            <div
              data-aos="zoom-in"
              className="col-md-3 text-left mt-4 partner_single_logo"
            >
              <img className="micro" src={micro} alt="" />
            </div>
            <div className="col-md-3"></div> */}
            <div className="col-md-1"></div>
            <div
              data-aos="zoom-in"
              className="col-md-4 text-right d-flex flex-row pr-5 partner_single_logo partner_double_loge"
            >
              <img className="binary" src={binary} alt="" />
              <img className="partnerLogo ml-2" src={partnerLogo} alt="" />
            </div>
            <div
              data-aos="zoom-in"
              className="col-md-3 text-center mt-4 partner_single_logo"
            >
              <img className="mt-2 ampex" src={blueLight} alt="" />
            </div>
            <div
              data-aos="zoom-in"
              className="col-md-3 text-left mt-4 partner_single_logo"
            >
              <img className="micro ml-3" src={micro} alt="" />
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
