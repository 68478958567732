import React from "react";
import portfolio5 from "../assect/images/logo/portfolio5.webp";

function ee00() {
  return (
    <section id="our_portfolio_section">
      <div className="ee01 menu_padding">
        <div className="hero_area">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column text-center">
                <h1 className="header-1 portfollio_hero_title">.</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
        <div className="row d-flex portfollio_header_img">
          <img src={portfolio5} className="img-191" alt="" />
        </div>
        <hr className="_line" />
        </div>

        <div className="body_section white_texture_bg body_section_phone">
          <div className="container pt-2rem pb-2rem">
            <div className="row d-flex align-items-center">
              <h2 className="header-3 portfollio_header_phone">
                Secure Mobile Device Communication
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  Any solution that uses a regular OS be it on a phone or any
                  device will always have the risk of a breach of that OS;
                  furthermore, any connection to a server to establish a secure
                  connection that has to traverse the ISP before encryption is
                  going to be at risk of being intercepted and/or diverted.
                  <br></br>
                  <br></br>
                  Our solution provides secure mobile field network and
                  communications for LAN and INTERNET usage.
                </p>
              </div>
            </div>

            <br></br>
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                Deception as a Defense
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description pb-2 pt-6 portfollio_desc_phone">
                  Confuse and Frustrate the Hacker
                </p>

                <p className="description pt-6 portfollio_desc_phone">
                  Deception-based security catches attackers as they make their
                  first movements within a network. It leverages the fact that,
                  after gaining access to a network, attackers always follow a
                  predictable attack pattern.
                </p>
              </div>
            </div>

            <br></br>
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                Building Cyber Labs
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  We have expertise to design, configure and fit out various
                  types of cyber labs:
                </p>
                <ul className="ee01_list">
                  <li>
                    <h1>Social Media Analytical Operations</h1>
                  </li>
                  <li>
                    <h1>Digital Forensics Analysis Lab</h1>
                  </li>
                  <li>
                    <h1>Training Centre of Excellence</h1>
                  </li>
                  <li>
                    <h1>
                      Enterprise Malware Analysis and Reverse Engineering{" "}
                    </h1>
                  </li>
                  <li>
                    <h1>Computer Incident Response </h1>
                  </li>
                  <li>
                    <h1>Security Operations Centers </h1>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ee00;