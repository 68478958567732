import React, { useEffect } from "react";

import { HashLink } from "react-router-hash-link";
import Aos from "aos";
import "aos/dist/aos.css";

import circleBullet from "../assect/images/icons/circle-bullet.webp";
import NetworkReview from "../assect/images/services/10.webp";
import VulnerabilityReview from "../assect/images/services/1.webp";
import DegitalForensices from "../assect/images/services/2.webp";
import Malware from "../assect/images/services/4.webp";
import SecurityBaseliness from "../assect/images/services/5.webp";
import VulnabilityTesting from "../assect/images/services/8.webp";
import security from "../assect/images/services/9.webp";
import networkConfig from "../assect/images/services/7.webp";

function Login() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <section id="services_trining_page_section">
      <div className="services_page menu_padding">
        <div className="hero_area">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column m-auto">
                <h1 className="header-1">Services</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="body_section">
          <div className="container">
            <div className="row pb-4rem mb-2rem services_tag_link">
              <div
                data-aos="zoom-in"
                className="col-md-6 text-right header_link pr-5"
              >
                <HashLink to="/services#network_architecture">
                  Network Architecture Review
                </HashLink>
              </div>
              <div data-aos="zoom-in" className="col-md-6 header_link pl-5">
                <HashLink to="/services#network_configuration">
                  Network Configuration Review
                </HashLink>
              </div>
              <div
                data-aos="zoom-in"
                className="col-md-6 text-right header_link pr-5"
              >
                <HashLink to="/services#design_implement">
                  Design and Implement Custom Security Baseline
                </HashLink>
              </div>
              <div data-aos="zoom-in" className="col-md-6 header_link pl-5">
                <HashLink to="/services#vulnerability_assessment">
                  Vulnerability Assessment and Penetration Testing
                </HashLink>
              </div>
              <div
                data-aos="zoom-in"
                className="col-md-6 text-right header_link pr-5"
              >
                <HashLink to="/services#vulnerability_management">
                  Vulnerability Management Review
                </HashLink>
              </div>
              <div data-aos="zoom-in" className="col-md-6 header_link pl-5">
                <HashLink to="/services#enterprise_malware">
                  Enterprise Malware Strategy
                </HashLink>
              </div>
              <div
                data-aos="zoom-in"
                className="col-md-6 text-right header_link pr-5"
              >
                <HashLink to="/services#security_options">
                  Security Operations Center (SoC)
                </HashLink>
              </div>

              <div data-aos="zoom-in" className="col-md-6 header_link pl-5">
                <HashLink to="/services#incident_response">
                  Incident Response and Digital Forensics
                </HashLink>
              </div>

              <section id="network_architecture" className="pt-12rem"></section>
            </div>

            <div className="row services_body_sec services_body_sec_desc">
              <div data-aos="zoom-in" className="col-md-4">
                <img  className="testimg mt-3" src={NetworkReview} alt="" />
              </div>
              <div className="col-md-8">
                <h2 data-aos="fade-left" className="header-2 fade_item">
                  Network Architecture Review
                </h2>
                <h2 data-aos="zoom-in" className="header-2 zoom_in">
                  Network Architecture Review
                </h2>
                <p
                  data-aos="zoom-in"
                  className="services_description text-justify mt-2rem mb-4rem"
                >
                  We can review your network architecture and compare it to best
                  practices; moreover, are you following a good segmentation
                  isolation strategy. Where are the ingress (inbound) and egress
                  (outbound) points. Are there protections in place at every
                  entry point. Have you followed best practices and have your
                  networks segmented by criticality. Finally, have you followed
                  best practices of separation of services and user data. No
                  malware and ransomware infection should take down an entire
                  network, if it does then there is no best practices of network
                  design at that organization. The assessment will finish with a
                  deliverable of a secure network architecture recommendation.
                  <section
                    id="network_configuration"
                    className="service_page_mt-4rem__"
                  ></section>
                </p>
              </div>
            </div>
            <div className="row services_body_sec">
              <hr />
            </div>

            <div className="row services_body_sec services_body_sec_desc services_img_phone">
              <div className="col-md-8">
                <div>
                  <h2
                    data-aos="fade-right"
                    className="header-2 text-right fade_item"
                  >
                    Network Configuration Review
                  </h2>
                  <h2 data-aos="zoom-in" className="header-2 zoom_in">
                    Network Configuration Review
                  </h2>
                  <p
                    data-aos="zoom-in"
                    className="services_description text-justify mt-2rem mb-4rem"
                  >
                    In a configuration review, the configuration for all of the
                    devices identified in the architecture are reviewed and
                    compared to best practices of configuration based on your
                    network architecture. Then an assessment of all of the
                    devices (routers, switches and firewalls) is conducted to
                    look for any vulnerabilities or weaknesses in the firmware
                    of the configuration of these devices. This in conjunction
                    with an architecture review will go much deeper than a
                    penetration test.
                    <section
                      id="design_implement"
                      className="service_page_mt-4rem__"
                    ></section>
                  </p>
                </div>
              </div>
              <div data-aos="zoom-in" className="col-md-4">
                <img
                  className="testimg mt-3 service_page_img_phone"
                  src={networkConfig}
                  alt=""
                />
              </div>
            </div>
            <div className="row services_body_sec">
              <hr />
            </div>

            <div className="row services_body_sec services_body_sec_desc">
              <div data-aos="zoom-in" className="col-md-4">
                <img
                  className="testimg mt-3 service_page_img_phone"
                  src={SecurityBaseliness}
                  alt=""
                />
              </div>
              <div className="col-md-8">
                <h2 data-aos="fade-left" className="header-2 fade_item">
                  Design and Implement Custom Security Baseliness
                </h2>
                <h2 data-aos="zoom-in" className="header-2 zoom_in">
                  Design and Implement Custom Security Baseliness
                </h2>
                <p
                  data-aos="zoom-in"
                  className="services_description text-justify mt-2rem mb-4rem"
                >
                  We have worked with many clients to build custom security
                  baselines for their Firewalls, Routers, Switches and Servers.
                  We can create a custom baseline package that will allow your
                  team to know the attack surface when a machine is place into
                  production.
                  <section
                    id="vulnerability_assessment"
                    className="mt-4rem"
                  ></section>
                </p>
              </div>
            </div>
            <div className="row services_body_sec">
              <hr />
            </div>
            <div className="row services_body_sec services_body_sec_desc services_img_phone">
              <div className="col-md-8">
                <div>
                  <h2
                    data-aos="fade-right"
                    className="header-2 text-right fade_item"
                  >
                    Vulnerability Assessment and Penetration Testing
                  </h2>
                  <h2 data-aos="zoom-in" className="header-2 zoom_in">
                    Vulnerability Assessment and Penetration Testing
                  </h2>
                  <p
                    data-aos="zoom-in"
                    className="services_description text-justify mt-2rem mb-4rem"
                  >
                    Extensive Experience in VA/PT for anything from mobile
                    devices to critical infrastructure.
                    <section
                      id="design_implement"
                      className="service_page_mt-4rem__"
                    ></section>
                  </p>
                </div>
              </div>
              <div data-aos="zoom-in" className="col-md-4">
                <img
                  className="testimg mt-3 service_page_img_phone"
                  src={VulnerabilityReview}
                  alt=""
                />
              </div>
              <section
                id="vulnerability_management"
                className="service_page_mt-4rem__vm"
              ></section>
            </div>

            <div className="row services_body_sec">
              <hr />
            </div>

            <div className="row services_body_sec services_body_sec_desc">
              <div data-aos="zoom-in" className="col-md-4">
                <img
                  className="testimg mt-3 service_page_img_phone"
                  src={VulnabilityTesting}
                  alt=""
                />
              </div>
              <div className="col-md-8">
                <h2 data-aos="fade-left" className="header-2 fade_item">
                  Vulnerability Management Review
                </h2>
                <h2 data-aos="zoom-in" className="header-2 zoom_in">
                  Vulnerability Management Review
                </h2>
                <p
                  data-aos="zoom-in"
                  className="services_description text-justify mt-2rem mb-4rem"
                >
                  In a vulnerability management review we compare the
                  organizations ability to handle a new 0 day vulnerability
                  announcement and review the effectiveness of the site
                  vulnerability management strategy.
                  <section
                    id="enterprise_malware"
                    className="service_page_mt-4rem__"
                  ></section>
                </p>
              </div>
            </div>

            <div className="row services_body_sec">
              <hr />
            </div>

            <div className="row services_body_sec services_body_sec_desc services_img_phone">
              <div className="col-md-8">
                <div>
                  <h2
                    data-aos="fade-right"
                    className="header-2 text-right fade_item"
                  >
                    Enterprise Malware Strategy
                  </h2>
                  <h2 data-aos="zoom-in" className="header-2 zoom_in">
                    Enterprise Malware Strategy
                  </h2>
                  <p
                    data-aos="zoom-in"
                    className="services_description text-justify mt-2rem mb-4rem"
                  >
                    Is your site prepared for a malware or ransomware attack? We
                    can review your site and design an enterprise malware
                    strategy that uses the advanced concepts of blackhole
                    routing and sinkhole domains.
                    <section
                      id="security_options"
                      className="service_page_mt-4rem__"
                    ></section>
                  </p>
                </div>
              </div>
              <div data-aos="zoom-in" className="col-md-4">
                <img
                  className="testimg mt-3 service_page_img_phone"
                  src={Malware}
                  alt=""
                />
              </div>
            </div>
            <div className="row services_body_sec">
              <hr />
            </div>
            <div className="row services_body_sec services_body_sec_desc">
              <div data-aos="zoom-in" className="col-md-4">
                <img
                  className="testimg mt-3 service_page_img_phone"
                  src={security}
                  alt=""
                />
              </div>
              <div className="col-md-8">
                <h2 data-aos="fade-left" className="header-2 fade_item">
                  Security Operations Center (SoC)
                </h2>
                <h2 data-aos="zoom-in" className="header-2 zoom_in">
                  Security Operations Center (SoC)
                </h2>
                <p
                  data-aos="zoom-in"
                  className="services_description text-justify mt-2rem"
                >
                  Design, development, and implementation of an enterprise
                  Security Operations Center (SOC) to provide comprehensive
                  information security monitoring by deploying following tools:
                </p>
                <div data-aos="zoom-in" className="pt-2rem">
                  <div className="d-flex circle_bullet justify-content-start">
                    <img src={circleBullet} alt="" />
                    Security information & Event Management tool [SIEM] –
                    Technology
                  </div>
                  <div className="d-flex circle_bullet justify-content-start">
                    <img src={circleBullet} alt="" />
                    Develop SOC Operation Policy, Processes and Procedures
                  </div>
                  <div data-aos="zoom-in" className="d-flex circle_bullet justify-content-start">
                    <img src={circleBullet} alt="" />
                    Conduct Technology, Process, and On The Job Training (OJT)
                  </div>
                  <section
                    id="incident_response"
                    className="service_page_mt-4rem__"
                  ></section>
                </div>
              </div>
            </div>
            <div className="row services_body_sec">
              <hr />
            </div>
            <div className="row services_body_sec services_body_sec_desc services_img_phone">
              <div className="col-md-8">
                <div>
                  <h2
                    data-aos="fade-right"
                    className="header-2 text-right fade_item"
                  >
                    Incident Response and Digital Forensics
                  </h2>
                  <h2 data-aos="zoom-in" className="header-2 zoom_in">
                    Incident Response and Digital Forensics
                  </h2>
                  <p
                    data-aos="zoom-in"
                    className="services_description text-justify mt-2rem mb-4rem"
                  >
                    Providing a structured methodology for handling security
                    incidents, breaches, and cyber threats. We will provide a
                    well-defined incident response plan allows you to
                    effectively identify, minimize the damage, and reduce the
                    cost of a cyber-attack, while finding and fixing the cause
                    to prevent future attack as well as collecting the evidence
                    from triage to possible litigation in a forensically sound
                    manner.
                    <section
                      id="compliance_audition"
                      className="service_page_mt-4rem__"
                    ></section>
                  </p>
                </div>
              </div>
              <div data-aos="zoom-in" className="col-md-4">
                <img
                  className="testimg mt-3 service_page_img_phone"
                  src={DegitalForensices}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
