import React from "react";

function quicklink() {
  return (
    <section id="quicklink_section">
      <div className="quicklink menu_padding">
        <div className="hero_area">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column text-center">
                <h1 className="header-1 portfollio_hero_title">Quick Link</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="body_section white_texture_bg body_section_phone">
          <div className="container pt-2rem pb-2rem">
            <div className="row d-flex align-items-center">
              <h2 className="header-3 portfollio_header_phone">
                U.S. Cert Cybersecurity &amp; Infrastructure Security Agency
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  <a href="https://us-cert.cisa.gov/" target="_blank" rel="noreferrer">
                    https://us-cert.cisa.gov/
                  </a>
                </p>
              </div>
            </div>

            <br></br>
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                Resources U.S. Cert Cybersecurity & Infrastructure Security
                Agency
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description pt-6 portfollio_desc_phone">
                  <a href="https://us-cert.cisa.gov/resources" target="_blank" rel="noreferrer">
                    https://us-cert.cisa.gov/resources
                  </a>
                </p>
              </div>
            </div>

            <br></br>
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                Cybersecurity Framework NIST
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  <a href="https://www.nist.gov/cyberframework" target="_blank" rel="noreferrer">
                    https://www.nist.gov/cyberframework
                  </a>
                </p>
              </div>
            </div>

            <br></br>
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                NICE Cybersecurity Workforce Framework
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  <a
                    href="https://niccs.cisa.gov/workforce-development/cyber-security-workforce-framework"
                    target="_blank" rel="noreferrer"
                  >
                    https://niccs.cisa.gov/workforce-development/cyber-security-workforce-framework
                  </a>
                </p>
              </div>
            </div>

            <br></br>
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                The Cyber Threat FBI Cyber
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  <a
                    href="https://www.fbi.gov/investigate/cyber"
                    target="_blank" rel="noreferrer"
                  >
                    https://www.fbi.gov/investigate/cyber
                  </a>
                </p>
              </div>
            </div>

            <br></br>
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                File a Report with the Internet Crime Complaint Center
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  <a href="https://www.ic3.gov/" target="_blank" rel="noreferrer">
                    https://www.ic3.gov/
                  </a>
                </p>
              </div>
            </div>

            <br></br>
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                Beyond Trust
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  <a href="https://www.beyondtrust.com/blog" target="_blank" rel="noreferrer">
                    https://www.beyondtrust.com/blog
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default quicklink;
