import React from 'react'
import '../assect/css/reset.min.css'
import client1 from '../assect/images/logo/client1.webp';
import client2 from '../assect/images/logo/client2.webp';
import client3 from '../assect/images/logo/client3.webp';
import client4 from '../assect/images/logo/client4.webp';
import client5 from '../assect/images/logo/client5.webp';

import client6 from '../assect/images/customer/client1.webp'
import client7 from '../assect/images/customer/client3.webp'
import client8 from '../assect/images/customer/client4.webp'
import client9 from '../assect/images/customer/client5.webp'


function homeSlider() {
    return (
        <div className="__slider ">
            <div className="__slide-track">
                <div className="__slide">
                    <img src={client1} alt="" height="170" width="170"></img>
                    <p>U.S Department Of <br />Homeland Security</p>
                </div>
                <div className="__slide">
                    <img src={client2} alt="" height="170" width="170"></img>
                    <p>U.S. Navy</p>
                </div>
                <div className="__slide" >
                    <img src={client3} alt="" height="170" width="170"></img>
                    <p>U.S. Department<br/>Of Defence</p>
                </div>
                <div className="__slide">
                    <img src={client4} alt="" height="170" width="170"></img>
                    <p>U.S Department Of<br/>Health & Human Services</p>
                </div>
                <div className="__slide">
                    <img src={client5} alt="" height="170" width="170"></img>
                    <p>U.S. Department<br/>Of Commerce</p>
                </div> 
                <div className="__slide">
                    <img src={client6} alt="" height="170" width="170"></img>
                    <p>Federal Bureau Of<br/>Investigation (FBI)</p>
                </div>
                <div className="__slide stc pt-2rem" >
                    <img className="mb-2rem" src={client7} alt="" height="100" width="120"></img>
                    <p className="pt-2">STC</p>
                </div>
                <div className="__slide">
                    <img src={client8} alt="" height="170" width="170"></img>
                    <p>U.S. Army</p>
                </div>
                <div className="__slide">
                    <img src={client9} alt="" height="170" width="170"></img>
                    <p>Central Intelligence<br/>Agency (C.I.A)</p>
                </div>

                <div className="__slide">
                    <img src={client1} alt="" height="170" width="170"></img>
                    <p>U.S Department Of <br />Homeland Security</p>
                </div>
                <div className="__slide">
                    <img src={client2} alt="" height="170" width="170"></img>
                    <p>U.S. Navy</p>
                </div>
                <div className="__slide" >
                    <img src={client3} alt="" height="170" width="170"></img>
                    <p>U.S. Department<br/>Of Defence</p>
                </div>
                <div className="__slide">
                    <img src={client4} alt="" height="170" width="170"></img>
                    <p>U.S Department Of<br/>Health & Human Services</p>
                </div>
                <div className="__slide">
                    <img src={client5} alt="" height="170" width="170"></img>
                    <p>U.S. Department<br/>Of Commerce</p>
                </div> 
                <div className="__slide">
                    <img src={client6} alt="" height="170" width="170"></img>
                    <p>Federal Bureau Of<br/>Investigation (FBI)</p>
                </div>
                <div className="__slide stc pt-2rem" >
                    <img className="mb-2rem" src={client7} alt="" height="100" width="120"></img>
                    <p className="pt-2">STC</p>
                </div>
                <div className="__slide">
                    <img src={client8} alt="" height="170" width="170"></img>
                    <p>U.S. Army</p>
                </div>
                <div className="__slide">
                    <img src={client9} alt="" height="170" width="170"></img>
                    <p>Central Intelligence<br/>Agency (C.I.A)</p>
                </div> 

                <div className="__slide">
                    <img src={client1} alt="" height="170" width="170"></img>
                    <p>U.S Department Of <br />Homeland Security</p>
                </div>
                <div className="__slide">
                    <img src={client2} alt="" height="170" width="170"></img>
                    <p>U.S. Navy</p>
                </div>
                <div className="__slide" >
                    <img src={client3} alt="" height="170" width="170"></img>
                    <p>U.S. Department<br/>Of Defence</p>
                </div>
                <div className="__slide">
                    <img src={client4} alt="" height="170" width="170"></img>
                    <p>U.S Department Of<br/>Health & Human Services</p>
                </div>
                <div className="__slide">
                    <img src={client5} alt="" height="170" width="170"></img>
                    <p>U.S. Department<br/>Of Commerce</p>
                </div> 
                <div className="__slide">
                    <img src={client6} alt="" height="170" width="170"></img>
                    <p>Federal Bureau Of<br/>Investigation (FBI)</p>
                </div>
                <div className="__slide stc pt-2rem" >
                    <img className="mb-2rem" src={client7} alt="" height="100" width="120"></img>
                    <p className="pt-2">STC</p>
                </div>
                <div className="__slide">
                    <img src={client8} alt="" height="170" width="170"></img>
                    <p>U.S. Army</p>
                </div>
                <div className="__slide">
                    <img src={client9} alt="" height="170" width="170"></img>
                    <p>Central Intelligence<br/>Agency (C.I.A)</p>
                </div>
            </div>
        </div>
    )
}

export default homeSlider

