import React,{useEffect} from 'react'
import Aos from 'aos'
import "aos/dist/aos.css"

function IndustriesCard( {image,title} ) {
    useEffect(() => {
        Aos.init({ duration: 1000});
    }, []);
    return (
        <div data-aos="zoom-in" className="i_single_card">
            <img src={image} alt=""></img>
            <p className= "i_text">{title}</p>
        </div>
    )
}

export default IndustriesCard
