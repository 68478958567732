import React from "react";

function underconstruction() {
  return (
    <section id="underconstruction_section">
      <div className="underconstruction menu_padding">
        <div className="hero_area">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column text-center">
                <h1 className="header-1 portfollio_hero_title">.</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default underconstruction;
