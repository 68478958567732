import React from "react";

import portfolio1 from "../assect/images/logo/portfolio1.webp";

function et01() {
  return (
    <section id="our_portfolio_section">
      <div className="et01 menu_padding">
        <div className="hero_area">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column text-center">
                <h1 className="header-1 portfollio_hero_title">.</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
        <div className="row d-flex portfollio_header_img">
          <img src={portfolio1} className="img-191" alt="" />
        </div>
        <hr className="_line" />
        </div>

        <div className="body_section white_texture_bg body_section_phone">
          <div className="container pt-2rem pb-2rem">
            <div className="row d-flex align-items-center">
              <h2 className="header-3 portfollio_header_phone">
                Shielding and Advanced Threat Detection
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  Our product is able to gain insight to your cybersecurity
                  platform by using our unique and enhanced cybersecurity tools
                  and techniques. It shields all-risk cyber threat traffic and
                  analyze the detailed cyber events that have occurred through a
                  secondary inspection technology capability. The tool also
                  provides system analytics that operates from an independent or
                  integrated SIEM monitoring platform to gain further analytical
                  details.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default et01;
