import React from "react";
// import { Link } from "react-router-dom";
import bullet from "../assect/images/icons/bullet.webp";
import ContactUs from "../Components/ContactUs";

function Career() {
  return (
    <section id="career_page_section">
      <div className="career_page menu_padding">
        <div className="hero_area">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column m-auto">
                <h1 className="header-1">Careers</h1>
                <hr />
              </div>
            </div>
          </div>
        </div>
        <div className="body_section">
          <div className="bg-white pt-2rem">
            <div className="container">
              <div className="row d-flex flex-column">
                <div className="description">
                  <p>
                    Enrich Group LLC is a rapidly growing company looking for
                    employees who are success-oriented and client focused. We
                    are continuously looking for information security
                    professionals to meet job requirements at various locations
                    in the U.S. and around the globe. We are a technology
                    services company and we are always focused to find the best
                    match for our customers and candidates.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="body_img_bg">
            <div className="container">
              <div className="row">
                <div className="bullet_sec">
                  <p className="bullet_title">
                    Please contact us if you have any of the following skills:
                  </p>
                  <div className="bullet">
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Security Architech</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Security Manager</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Security Auditor</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Chief Information System Officer (CISO)</p>
                    </div>

                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Cryptographer</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Business Continuity Manager</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Penetration Tester</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Malware Analyst</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <div className="container">
              <div className="row">
                <div className="description pt-2rem">
                  <p>
                    As a services company, we understand that retaining highly
                    qualified professionals is paramount to our success. Enrich
                    Group strives to provide an engaging environment that
                    maximizes employee contributions and provides opportunities
                    for growth.
                    <br /> <br />
                    We are an equal opportunity employer. All qualified
                    applicants will be considered for employment without regard
                    to race, color, religion, gender, age, national origin,
                    genetic information, sexual orientation, disability, veteran
                    status or any other status protected by law.
                    <br />
                    <br />
                    We offers an extensive benefits program with options for
                    every employee to select a benefits package tailored to suit
                    their needs. We offer:{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="body_color_bg">
            <div className="container">
              <div className="row">
                <div className="bullet_sec">
                  <div className="bullet">
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Medical</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Dental</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Vision</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Flexible Spending Account</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>401K retirement account</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Life Insurancet</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Long-term disability insurance</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Short-term disability insurance</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Educational assistance</p>
                    </div>
                    <div className="single_item d-flex">
                      <img src={bullet} alt="" />
                      <p>Personal Time Off</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white mb-4rem mt-4">
          <ContactUs></ContactUs>
        </div>
      </div>
    </section>
  );
}

export default Career;
