import React from "react";
import conHome from "../assect/images/icons/contact_home.webp";
import conPhone from "../assect/images/icons/contact_phn.webp";
import conMail from "../assect/images/icons/contact_mail.webp";

function Contact() {
  function formsubmit(event) {
    event.preventDefault();
    document.getElementById("warning").innerHTML = "";
    if (document.getElementById("fullname").value == "") {
      document.getElementById("warning").innerHTML =
        "Please Enter your Full name";
      return;
    }
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      document.getElementById("email").value === "" ||
      !regEmail.test(document.getElementById("email").value)
    ) {
      document.getElementById("warning").innerHTML = "Please Enter Valid Email";
      return;
    }
    if (document.getElementById("message").value === "") {
      document.getElementById("warning").innerHTML =
        "Please Enter your Message";
      return;
    }
    document.getElementById("warning").innerHTML = "Please Wait. Sending...";
    window.Email.send({
      SecureToken: "78281c33-0f7e-485f-96f6-26629ccf5d25",
      To: "info@enrichgrp.com",
      From: document.getElementById("email").value,
      Subject: "Redirect from Enrich Official Website",
      Body: document.getElementById("message").value,
    }).then((message) => {
      console.log(message);
      if (message === "OK") {
        document.getElementById("warning").innerHTML =
          "Email Successfully send";
        document.getElementById("fullname").value = "";
        document.getElementById("email").value = "";
        document.getElementById("message").value = "";
      } else {
        document.getElementById("warning").innerHTML =
          "Something Went Wrong.Please try again letter.";
      }
    });
  }
  return (
    <section id="contact_section">
      <div className="cantact_page menu_padding">
        <div className="contact_sec">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column m-auto">
                <h1 className="header-1">
                  Contact <span>Us</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="body_section">
          <div className="container">
            <div className="row">
              <div className="col-md-6 left">
                <div className="top">
                  <h3>United State Office:</h3>
                  <p>
                    All inquiries can be made via telephone or by emailing us at{" "}
                    <span>info@enrichgrp.com</span>
                  </p>
                </div>

                <iframe
                  className="contact__map mt-5"
                  src="https://maps.google.com/maps?q=1629%20K%20St%2C%20Suite%20300%20Washington%2C%20DC%2020006&t=m&z=10&output=embed&iwloc=near"
                  alt=""
                  title=""
                  frameBorder="0"
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
              <div className="col-md-6 right">
                <h2 className="tytle">Send Message</h2>
                <hr className="h2_line" />
                <form action="">
                  <input
                    type="text"
                    id="fullname"
                    name="fullname"
                    placeholder="Full Name"
                    required
                  />
                  <input
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Email"
                    required
                  />
                  <textarea
                    type="text"
                    id="message"
                    name="message"
                    placeholder="Type Your Message. Don't share any private info Here."
                    required
                  />
                  <button
                    className="submit-btn"
                    onClick={(event) => {
                      formsubmit(event);
                    }}
                  >
                    Send
                  </button>
                  <span
                    id="warning"
                    style={{ margin: "20px", color: "red" }}
                  ></span>
                </form>
                <div className="bottom pt-5">
                  <div className="address d-flex">
                    <div className="_img">
                      <img src={conHome} alt="" />
                    </div>
                    <div className="add_description">
                      <h4>Address</h4>
                      <p>
                        1629 K St, Suite 300
                        <br />
                        Washington, DC 20006
                      </p>
                    </div>
                  </div>
                  <div className="address d-flex">
                    <div className="_img phone_icon">
                      <img src={conPhone} alt="" />
                    </div>
                    <div className="add_description">
                      <h4>Phone</h4>
                      <p>
                        P: 202 753 2653
                        <br />
                        F: 202753 2603
                      </p>
                    </div>
                  </div>
                  <div className="address d-flex">
                    <div className="_img phone_icon">
                      <img src={conMail} alt="" />
                    </div>
                    <div className="add_description">
                      <h4>Email</h4>
                      <p>info@enrichgrp.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
