import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import search from "../assect/images/icons/search.webp";
import fb from "../assect/images/icons/fb.webp";
import In from "../assect/images/icons/in.webp";
import instagram from "../assect/images/icons/instagram.webp";
import twitter from "../assect/images/icons/twitter.webp";
import youtube from "../assect/images/icons/youtube.webp";

function Footer() {
  return (
    <div>
      <div className="footer_area">
        <div className="container">
          <div className="footer_nav d-flex row justify-content-between">
            <div className="footer_menu">
              <Link to="#">
                About us
                <div className="dropdown_nav d-flex flex-column footer_item">
                  <HashLink to="/about#about_page_section">
                    Our Company
                  </HashLink>
                  <HashLink to="/capability#capability_page_section">
                    Capability
                    <br />
                    Statement
                  </HashLink>
                  <HashLink to="/career#career_page_section">Career</HashLink>
                  <HashLink to="/customeraffiliation#customer_affiliation_section">
                    Customer &<br />
                    affiliation
                  </HashLink>
                </div>
              </Link>
              <div className="pb-4"></div>
              <HashLink to="/#industries_section">
                Industries We
                <br />
                Enrich
              </HashLink>
              <div className="pb-4"></div>
              <div className="none_for_laptop">
                <Link to="#">
                  Solution & Products
                  <div className="dropdown_nav d-flex flex-column footer_item">
                    <HashLink
                      to="/et01#our_portfolio_section"
                      className="d-flex flex-row"
                    >
                      <p className="d-flex align-items-center mr-2 link_header">
                        ET 01
                      </p>
                      <p>
                        Shielding & Advanced
                        <br />
                        Threat Detection
                      </p>
                    </HashLink>
                    <HashLink
                      to="/et02#our_portfolio_section"
                      className="d-flex flex-row"
                    >
                      <p className="d-flex align-items-center mr-2 link_header">
                        ET 02
                      </p>
                      <p>
                        Analytical Solution
                        <br />
                        Open-Source Intelligence
                        <br />
                        (OSINT)
                        <br />
                        Dark Web Solution
                      </p>
                    </HashLink>
                    <HashLink
                      to="/et03#our_portfolio_section"
                      className="d-flex flex-row"
                    >
                      <p className="d-flex mr-2 link_header">ET 03</p>
                      <p className="mt-1">Network Monitoring System</p>
                    </HashLink>
                    <HashLink
                      to="/ee01#our_portfolio_section"
                      className="d-flex flex-row"
                    >
                      <p className="d-flex align-items-center mr-2 link_header">
                        EE 01
                      </p>
                      <p>
                        Secure Mobile Device
                        <br />
                        Communication
                        <br />
                        Deception as Defense
                        <br />
                        Build Custom Cyber Labs
                      </p>
                    </HashLink>
                  </div>
                </Link>
              </div>
            </div>
            <div className="footer_menu">
              <div className="none_for_phone">
                <Link to="#">
                  Solution & Products
                  <div className="dropdown_nav d-flex flex-column footer_item">
                    <HashLink
                      to="/et03#our_portfolio_section"
                      className="d-flex flex-row"
                    >
                      <p className="d-flex align-items-center mr-2 link_header">
                        ET 01
                      </p>
                      <p>
                        Shielding & Advanced
                        <br />
                        Threat Detection
                      </p>
                    </HashLink>
                    <HashLink
                      to="/et02#our_portfolio_section"
                      className="d-flex flex-row"
                    >
                      <p className="d-flex align-items-center mr-2 link_header">
                        ET 02
                      </p>
                      <p>
                        Analytical Solution
                        <br />
                        Open-Source Intelligence
                        <br />
                        (OSINT)
                        <br />
                        Dark Web Solution
                      </p>
                    </HashLink>
                    <HashLink
                      to="/et03#our_portfolio_section"
                      className="d-flex flex-row"
                    >
                      <p className="d-flex mr-2 link_header">ET 03</p>
                      <p className="mt-1">Network Monitoring System</p>
                    </HashLink>
                    <HashLink
                      to="/ee01#our_portfolio_section"
                      className="d-flex flex-row"
                    >
                      <p className="d-flex align-items-center mr-2 link_header">
                        EE 01
                      </p>
                      <p>
                        Secure Mobile Device
                        <br />
                        Communication
                        <br />
                        Deception as Defense
                        <br />
                        Build Custom Cyber Labs
                      </p>
                    </HashLink>
                    <HashLink className="d-flex flex-row">
                      <p className="d-flex align-items-center mr-2 link_header">
                        EE 00
                      </p>
                      <p>Machineries & Equipment</p>
                    </HashLink>
                  </div>
                </Link>
              </div>
            </div>
            <div className="footer_menu">
              <Link to="#">
                Services & Training
                <div className="dropdown_nav d-flex flex-column footer_item">
                  <HashLink to="/services#services_trining_page_section">
                    services
                  </HashLink>
                  <HashLink to="/training#trining_page_section">
                    Traning
                  </HashLink>
                </div>
                <div className="footer_menu d-flex flex-column none_for_laptop">
                  <HashLink to="/partners#partner_page_section">
                    Partners
                  </HashLink>
                </div>
                <div className=" footer_menu none_for_laptop">
                  <Link to="#">
                    Resources
                    <div className="dropdown_nav d-flex flex-column footer_item">
                      <HashLink to="/quicklink#quicklink_section">
                        Quick Link
                      </HashLink>
                      <HashLink to="/underconstruction#underconstruction_section">
                        Use cases
                      </HashLink>
                      <HashLink
                        to="/underconstruction#underconstruction_section"
                        className="eBOOK"
                      >
                        eBOOK
                      </HashLink>
                      {/* <HashLink to="/use-case#usecase_page_section">Video</HashLink> */}
                    </div>
                  </Link>
                  <div className="pb-4"></div>
                  <Link to="#">
                    News & Events
                    <div className="dropdown_nav d-flex flex-column footer_item">
                      <HashLink to="/underconstruction#underconstruction_section">
                        Our Activities
                      </HashLink>
                      <HashLink to="/underconstruction#underconstruction_section">
                        Latest news
                      </HashLink>
                      <HashLink to="/underconstruction#underconstruction_section">
                        Event
                      </HashLink>
                    </div>
                  </Link>
                  <div className="pb-4"></div>
                  <HashLink to="/contact#contact_section">Contact Us</HashLink>
                </div>
              </Link>
            </div>
            <div className="footer_menu">
              <div className="footer_menu d-flex flex-column none_for_phone">
                <HashLink to="/partners#partner_page_section">
                  Partners
                </HashLink>
              </div>
            </div>
            <div className=" footer_menu ">
              <div className="none_for_phone">
                <Link to="#">
                  Resources
                  <div className="dropdown_nav d-flex flex-column footer_item">
                    <HashLink to="/quicklink#quicklink_section">
                      Quick Link
                    </HashLink>
                    <HashLink to="/underconstruction#underconstruction_section">
                      Use cases
                    </HashLink>
                    <HashLink
                      to="/underconstruction#underconstruction_section"
                      className="eBOOK"
                    >
                      eBOOK
                    </HashLink>
                    {/* <HashLink to="/use-case#usecase_page_section">Video</HashLink> */}
                  </div>
                </Link>
              </div>
            </div>

            <div className="footer_menu">
              <div className="none_for_phone">
                <Link to="#">
                  News & Events
                  <div className="dropdown_nav d-flex flex-column footer_item">
                    <HashLink to="/underconstruction#underconstruction_section">
                      Our Activities
                    </HashLink>
                    <HashLink to="/underconstruction#underconstruction_section">
                      Latest news
                    </HashLink>
                    <HashLink to="/underconstruction#underconstruction_section">
                      Event
                    </HashLink>
                  </div>
                </Link>
              </div>
            </div>

            <div className="footer_menu d-flex flex-column  text-center">
              <div className="none_for_phone">
                <HashLink to="/contact#contact_section">Contact Us</HashLink>
              </div>
            </div>
          </div>
          <div className="_footer_line">
            <hr className="footer_line" />
          </div>

          <div className="row end_sec mt-2rem">
            <div className="search col-md-7">
              <form action="">
                <input type="text" name="search" />
                <button type="submit">
                  <img src={search} alt="search icon" /> Search
                </button>
              </form>
            </div>
            <div className="social_area col-md-5 d-flex justify-content-end text-center">
              <p>Follow us on</p>
              <a href="https://www.redport-ia.com/#solutions">
                <img src={instagram} alt="instagram" />
              </a>
              <a href="">
                <img src={fb} alt="fb" />
              </a>
              <a href="">
                <img src={twitter} alt="twitter" />
              </a>
              <a href="">
                <img src={In} alt="in" />
              </a>
              <a href="">
                <img src={youtube} alt="youtube" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <footer className="text-center">&copy; 2021 Enrich Group LLC.</footer>
    </div>
  );
}

export default Footer;
