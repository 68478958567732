import React from "react";

import enrichPDF from "../assect/images/capabilitypdf.pdf";
import enrichJPG from "../assect/images/capability.jpg";

function Cpability() {
  return (
    <section id="capability_page_section">
      <div className="capability_page menu_padding">
        <div className="hero_area">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column m-auto text-center">
                <h1 className="header-1">Capability Statement</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="body_section texture_bg">
          <div className="container">
          <div className="row d-flex justify-content-center cap_msg_display">
            <h3>Press The Download Button For Capability Statement</h3>
              {/* <img src={enrichJPG} alt="Capability Statement"></img> */}
              {/* <iframe src={enrichPDF} frameBorder="0"></iframe> */}
            </div>
            <div className="row d-flex justify-content-center pdf_display">
              <img src={enrichJPG} alt="Capability Statement"></img>
              {/* <iframe src={enrichPDF} frameBorder="0"></iframe> */}
            </div>
          </div>
          <div className="container ">
            <div className="row d-flex justify-content-center flex-column">
              <div className="contact_btn">
                <a href={enrichPDF} download="Enrich group">
                  Download
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cpability;
