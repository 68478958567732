import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import Aos from "aos";
import "aos/dist/aos.css";
import "../assect/css/bootstrap.min.css";

import IndustriesCard from "../Components/IndustriesCard";
import Particles from "react-particles-js";
import affiliation1 from "../assect/images/logo/affiliation-1.webp";
import affiliation2 from "../assect/images/logo/affiliation-2.webp";
import affiliation3 from "../assect/images/logo/affiliation-3.webp";
import affiliation4 from "../assect/images/customer/aff1.webp";
import affiliation5 from "../assect/images/customer/aff3.webp";
import affiliation6 from "../assect/images/customer/aff4.webp";
import industrisImg1 from "../assect/images/home/industries1.webp";
import industrisImg2 from "../assect/images/home/industries2.webp";
import industrisImg3 from "../assect/images/home/industries3.webp";
import industrisImg4 from "../assect/images/home/industries4.webp";
import industrisImg5 from "../assect/images/home/industries5.webp";
import HomeSlider from "../Components/homeSlider";
// product portfolio
import portfolio1 from "../assect/images/logo/portfolio1.webp";
import portfolio2 from "../assect/images/logo/portfolio2.webp";
import portfolio3 from "../assect/images/logo/portfolio3.webp";
import portfolio4 from "../assect/images/logo/portfolio4.webp";
import portfolio5 from "../assect/images/logo/portfolio5.webp";

import rightArrow from "../assect/images/icons/right-arrow.webp";
import rightWhiteArrow from "../assect/images/icons/green_arrow.webp";

import aiBody from "../assect/images/home/ai-body.webp";

function Home() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <section id="homepage_section">
      <div id="home_page">
        <div className="_hero-area">
          <div className="container">
            <div className="row">
              <div className="hero-area pl-2rem pr-2">
                <div className="text-left">
                  <h2 data-aos="slide-right" className="header-2">
                    Artificial Intelligence (AI) Driven Technology{" "}
                  </h2>
                  <h2 data-aos="slide-right" className="header-2">
                    Cyber-Security
                  </h2>
                  <h2 data-aos="slide-right" className="header-2">
                    OSINT (Open Source Intelligence)
                  </h2>
                </div>
                <div
                  data-aos="zoom-in"
                  className="hero_description mt-4rem pt-3"
                >
                  <p>
                    Enrich Group LLC is a world leading technology company
                    delivering End to End Cybersecurity and OSINT custom
                    solutions to National Security & Defense, Law Enforcement,
                    Banking/Financial, Health Care and Education.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="industries_section" className="industries_sec">
          <div className="container">
            <div className="row d-flex flex-column justify-content-center">
              <div className="title">
                <h1 className="header-2">
                  Industries We <span>Enrich</span>
                </h1>
              </div>
              <hr className="industries_line"></hr>
            </div>
            <div className="industries_card mt-2rem d-flex justify-content-center">
              <div className="mr-2rem">
                <HashLink to="/#industries_section">
                  <IndustriesCard
                    image={industrisImg1}
                    title={"National Security & Defense"}
                  ></IndustriesCard>
                </HashLink>
              </div>
              <div className="pl-4rem pr-4rem">
                <HashLink to="/#industries_section">
                  <IndustriesCard
                    image={industrisImg2}
                    title={"Law Enforcement"}
                  ></IndustriesCard>
                </HashLink>
              </div>
              <div className="ml-2rem">
                <HashLink to="/#industries_section">
                  <IndustriesCard
                    image={industrisImg3}
                    title={"Banking & Financial"}
                  ></IndustriesCard>
                </HashLink>
              </div>
            </div>
            <div className="industries_card mt-2rem d-flex justify-content-center">
              <div className="pr-4rem">
                <HashLink to="/#industries_section">
                  {" "}
                  <IndustriesCard
                    image={industrisImg4}
                    title={"Health Care"}
                  ></IndustriesCard>
                </HashLink>
              </div>
              <div className="pl-4rem">
                <HashLink to="/#industries_section">
                  <IndustriesCard
                    image={industrisImg5}
                    title={"Education"}
                  ></IndustriesCard>
                </HashLink>
              </div>
            </div>

            <div className="end_text">
              <p>
                We deliver industry leading Cybersecurity and Open Source
                Intelligence (OSINT) solutions, products and custom training
                that provides you actionable data and enables you to make the
                right decision.
              </p>
            </div>
          </div>
        </section>

        <div className="peruse_sec">
          <Particles
            className="particals"
            params={{
              particles: {
                number: {
                  value: 110,
                },
                size: {
                  value: 3,
                },
              },
            }}
          />
          <Particles
            className="particals_phone"
            params={{
              particles: {
                number: {
                  value: 35,
                },
                size: {
                  value: 3,
                },
              },
            }}
          />

          <div className="content-sec d-flex align-items-center">
            <div className="container">
              <div className="row d-flex flex-row partical_body justify-content-between">
                <div
                  data-aos="slide-up"
                  className="col-md-6 partical_card_item pr-5"
                >
                  <div className="left single_content">
                    <div className="title">
                      <h1>
                        One Cyber Attack <br /> in Every 39 Seconds
                      </h1>
                    </div>
                    <hr className="line"></hr>
                    <div className="description">
                      <p>
                        Over $3.5 Billion Lost to Cyber Crime Globally in 2019,
                        with Our Advanced AI Decoy System it detects and stops
                        the attacks using reverse deceptive techniques and
                        tactics.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 partical_card_d-none"></div>
                <div
                  data-aos="slide-down"
                  className="col-md-6 partical_card_item pl-5"
                >
                  <div className="right single_content">
                    <div className="title">
                      <h1>
                        Open Source Intelligence <br /> (OSINT)
                      </h1>
                    </div>
                    <hr className="line"></hr>
                    <div className="description">
                      <p>
                        Our solution collects and analyzes data from all open
                        sources (Social Media, Open, Deep and Dark Web) and
                        provides actionable insights in near real time.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="portfolio_sec pt-4rem pb-4rem">
          <div className="container">
            <div className="row d-flex flex-column text-center">
              <h1 className="header-2">
                <span>Our</span> Portfolio
              </h1>
            </div>
            <div className="row">
              <div className="col-md-2"></div>
              <div data-aos="zoom-in" className="col-md-4 text-center mt-2rem">
                <HashLink to="/et01#our_portfolio_section">
                  <img src={portfolio1} alt="" />
                  <p className="description">
                    <img
                      className="portfollio_black_arrow"
                      src={rightArrow}
                      alt="arrow"
                    />
                    <img
                      className="portfollio_green_arrow"
                      src={rightWhiteArrow}
                      alt="arrow"
                    />
                    Shielding &<br />
                    Advanced Threat Detection
                  </p>
                </HashLink>
              </div>
              <div data-aos="zoom-in" className="col-md-4 text-center mt-2rem">
                <HashLink to="/et02#our_portfolio_section">
                  <img src={portfolio2} alt="" />
                  <p className="description">
                    <img
                      className="portfollio_black_arrow"
                      src={rightArrow}
                      alt="arrow"
                    />
                    <img
                      className="portfollio_green_arrow"
                      src={rightWhiteArrow}
                      alt="arrow"
                    />
                    Analytical Solution
                    <br />
                    <img
                      className="portfollio_black_arrow"
                      src={rightArrow}
                      alt="arrow"
                    />
                    <img
                      className="portfollio_green_arrow"
                      src={rightWhiteArrow}
                      alt="arrow"
                    />
                    Open-Source Intelligence (OSINT)
                    <br />
                    <img
                      className="portfollio_black_arrow"
                      src={rightArrow}
                      alt="arrow"
                    />
                    <img
                      className="portfollio_green_arrow"
                      src={rightWhiteArrow}
                      alt="arrow"
                    />
                    Dark Web Solution
                  </p>
                </HashLink>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-2"></div>
              <div data-aos="zoom-in" className="col-md-4 text-center mt-2rem">
                <HashLink to="/et03#our_portfolio_section">
                  <img src={portfolio3} alt="" />
                  <p className="description">
                    <img
                      className="portfollio_black_arrow"
                      src={rightArrow}
                      alt="arrow"
                    />
                    <img
                      className="portfollio_green_arrow"
                      src={rightWhiteArrow}
                      alt="arrow"
                    />
                    Network Monitoring System
                  </p>
                </HashLink>
              </div>
              <div data-aos="zoom-in" className="col-md-4 text-center mt-2rem">
                <HashLink to="/ee01#our_portfolio_section">
                  <img src={portfolio4} />
                  <p className="description">
                    <img
                      className="portfollio_black_arrow"
                      src={rightArrow}
                      alt="arrow"
                    />
                    <img
                      className="portfollio_green_arrow"
                      src={rightWhiteArrow}
                      alt="arrow"
                    />
                    Secure Mobile Device Communication
                    <br />
                    <img
                      className="portfollio_black_arrow"
                      src={rightArrow}
                      alt="arrow"
                    />
                    <img
                      className="portfollio_green_arrow"
                      src={rightWhiteArrow}
                      alt="arrow"
                    />
                    Deception as Defense
                    <br />
                    <img
                      className="portfollio_black_arrow"
                      src={rightArrow}
                      alt="arrow"
                    />
                    <img
                      className="portfollio_green_arrow"
                      src={rightWhiteArrow}
                      alt="arrow"
                    />
                    Build Custom Cyber Labs
                  </p>
                </HashLink>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-2"></div>
              <div className="col-md-2"></div>
              <div data-aos="zoom-in" className="col-md-4 text-center mt-2rem">
                <HashLink
                //  to="/ee00#our_portfolio_section"
                 >
                  <img src={portfolio5} />
                  <p className="description">
                    <img
                      className="portfollio_black_arrow"
                      src={rightArrow}
                      alt="arrow"
                    />
                    <img
                      className="portfollio_green_arrow"
                      src={rightWhiteArrow}
                      alt="arrow"
                    />
                    Machineries & Equipment
                  </p>
                </HashLink>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        </div>

        <div className="ai_section">
          <div className="container">
            <div className="row">
              <div className="col-md-5 d-flex justify-content-around mb-4rem">
                <img data-aos="zoom-in" src={aiBody} alt="" />
              </div>
              <div className="col-md-7 d-flex flex-column">
                <h2 data-aos="zoom-in" className="header-2 mt-4rem-ai mb-2rem">
                  Creating AI <span>For A</span> Secure Future{" "}
                  <hr className="_line"></hr>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="client_section">
          <div className="container">
            <div className="row d-flex flex-column justify-content-center">
              <div className="title">
                <h1 className="text-center header-2">
                  <span>Our</span> Clients
                </h1>
              </div>
              <hr className="industries_line"></hr>
              <div className="description">
                <p className="text-center mb-2rem pt-2">
                  Enrich Group is proud to have partnered with the following
                  organizations
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <HomeSlider className="client_slider"></HomeSlider>
          </div>
        </div>

        <div className="affiliation">
          <div className="container">
            <div className="row d-flex flex-column justify-content-center">
              <div className="title">
                <h1 className="text-center header-2">Affiliation</h1>
              </div>
              <hr className="industries_line "></hr>
              <div className="logo_sec d-flex justify-content-between  flex--wrap">
                <div
                  data-aos="zoom-in"
                  className="single_item affiliation1 description"
                >
                  <img src={affiliation1} alt=""></img>
                  <p>U.S. Commercial Service</p>
                </div>
                <div data-aos="zoom-in" className="single_item description">
                  <img src={affiliation2} alt=""></img>
                  <p>Association of the United States Army</p>
                </div>
                <div
                  data-aos="zoom-in"
                  className="single_item affiliation6 description"
                >
                  <img src={affiliation6} alt=""></img>
                  <p>National Institute Cybersecurity Education</p>
                </div>
                <div data-aos="zoom-in" className="single_item description">
                  <img src={affiliation3} alt=""></img>
                  <p>U.S. Department of Veteran Affairs</p>
                </div>
                <div
                  data-aos="zoom-in"
                  className="single_item affiliation5 description"
                >
                  <img src={affiliation5} alt=""></img>
                  <p>National Institute of Standards and Technology</p>
                </div>
                <div data-aos="zoom-in" className="single_item description">
                  <img src={affiliation4} alt=""></img>
                  <p>Cybersecurity & Infrastructure Security Agency</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
