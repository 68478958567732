import React from "react";

import portfolio3 from "../assect/images/logo/portfolio3.webp";

function et03() {
  return (
    <section id="our_portfolio_section">
      <div className="et03 menu_padding">
        <div className="hero_area">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column text-center">
                <h1 className="header-1 portfollio_hero_title">.</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
        <div className="row d-flex portfollio_header_img">
          <img src={portfolio3} className="img-191" alt="" />
        </div>
        <hr className="_line" />
        </div>

        <div className="body_section white_texture_bg body_section_phone">
          <div className="container pt-2rem pb-2rem">
            <div className="row d-flex align-items-center">
              <h2 className="header-3 portfollio_header_phone">
                Network Monitoring System
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-justify">
                <p className="description portfollio_desc_phone">
                  Our network device that monitors all communications that it
                  can ingest from any span or mirror port. It processes the
                  information through a series of different events to create a
                  visualization that displays your network nodes and
                  communications pathways.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default et03;
