import React from "react";

import client1 from "../assect/images/customer/client1.webp";
import client2 from "../assect/images/customer/client2.webp";
import client3 from "../assect/images/customer/client3.webp";
import client4 from "../assect/images/customer/client4.webp";
import client5 from "../assect/images/customer/client5.webp";

import client6 from "../assect/images/logo/client1.webp";
import client7 from "../assect/images/logo/client2.webp";
import client9 from "../assect/images/logo/client4.webp";
import client10 from "../assect/images/logo/client5.webp";

import aff1 from "../assect/images/customer/aff1.webp";
import aff2 from "../assect/images/customer/aff2.webp";
import aff3 from "../assect/images/customer/aff3.webp";
import aff4 from "../assect/images/customer/aff4.webp";

import affiliation2 from "../assect/images/logo/affiliation-2.webp";
import affiliation3 from "../assect/images/logo/affiliation-3.webp";

function CustomerAffiliation() {
  return (
    <section id="customer_affiliation_section">
      <div class="customer_page menu_padding">
        <div class="container">
          <div class="row hero_title pt-4rem">
            <div class="text-center col-md-12">
              <h1 className="header-2">Clients</h1>
            </div>
          </div>
          <div className="client_logo justify-content-around d-flex  row">
            <div data-aos="zoom-in" className="description ">
              <img src={client1} alt="" />
              <p className="text-center pt-2">
                Federal Bureau Of
                <br />
                Investigation (FBI)
              </p>
            </div>
            <div data-aos="zoom-in" className="description ">
              <img src={client2} alt="" />
              <p className="text-center pt-2">
                U.S. Department
                <br />
                Of Defence
              </p>
            </div>
            <div data-aos="zoom-in" className="description ">
              <img className="stc" src={client3} alt="" />
              <p className="text-center pt-4">STC </p>
            </div>
            <div data-aos="zoom-in" className="description ">
              <img src={client4} alt="" />
              <p className="text-center pt-2">U.S. Army </p>
            </div>
            <div data-aos="zoom-in" className="description ">
              <img src={client5} alt="" />
              <p className="text-center pt-2">
                Central Intelligence
                <br />
                Agency (C.I.A)
              </p>
            </div>
            <div data-aos="zoom-in" className="description temp_logo_1">
              <img src={client6} alt="" />
              <p className="text-center pt-2">
                U.S Department Of
                <br />
                Homeland Security
              </p>
            </div>
          </div>
          <div className=" client_logo justify-content-around d-flex  row">
            <div data-aos="zoom-in" className="description hide_logo_1">
              <img src={client6} alt="" />
              <p className="text-center pt-2">
                U.S Department Of
                <br />
                Homeland Security
              </p>
            </div>
            <div data-aos="zoom-in" className="description">
              <img src={client7} alt="" />
              <p className="text-center pt-2">U.S. Navy</p>
            </div>
            <div data-aos="zoom-in" className="description">
              <img src={client9} alt="" />
              <p className="text-center pt-2">
                U.S Department Of <br />
                Health & Human Services
              </p>
            </div>
            <div data-aos="zoom-in" className="description">
              <img src={client10} alt="" />
              <p className="text-center pt-2">
                U.S. Department
                <br />
                Of Commerce
              </p>
            </div>
          </div>

          <div className="row">
            <div class="text-center col-md-12 mt-2rem mb-2rem">
              <h1 className="header-2">Affiliation</h1>
            </div>
          </div>

          <div className="row affiliation_desktop">
            <div className="affiliation_logo col-md-8 d-flex justify-content-between mt-2rem ">
              <div data-aos="zoom-in" className="description">
                <img className="aff1" src={aff1} alt="" />
                <p className="text-center pt-2">
                  Cybersecurity &<br />
                  Infrastructure
                  <br />
                  Security Agency
                </p>
              </div>
              <div data-aos="zoom-in" className="description">
                <img className="aff3" src={aff3} alt="" />
              </div>
              <div data-aos="zoom-in" className="description">
                <img className="aff4" src={aff4} alt="" />
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row affiliation_desktop">
            <div className="col-md-3"></div>
            <div className="affiliation_logo affiliation_logo_second col-md-8 d-flex justify-content-between pb-4rem">
              <div data-aos="zoom-in" className="description">
                <img className="aff2" src={aff2} alt="" />
              </div>
              <div data-aos="zoom-in" className="description">
                <img className="aff1" src={affiliation2} alt="" />
                <p className="text-center pt-2">
                  Association of the
                  <br />
                  United States Army
                </p>
              </div>
              <div data-aos="zoom-in" className="description">
                <img className="aff1" src={affiliation3} alt="" />
                <p className="text-center pt-2">
                  U.S. Department of
                  <br />
                  Veteran Affairs
                </p>
              </div>
            </div>
          </div>
          <div className="affiliation_mobile client_logo justify-content-around d-flex  row">
            <div data-aos="zoom-in" className="description ">
              <img className="aff1" src={aff1} alt="" />
              <p className="text-center pt-2">
                Cybersecurity &<br />
                Infrastructure
                <br />
                Security Agency
              </p>
            </div>
            <div data-aos="zoom-in" className="description ">
              <img className="aff3" src={aff3} alt="" />
            </div>
            <div data-aos="zoom-in" className="description ">
              <img className="aff4" src={aff4} alt="" />
            </div>

            <div data-aos="zoom-in" className="description">
              <img className="aff2" src={aff2} alt="" />
            </div>
            <div data-aos="zoom-in" className="description ">
              <img className="aff1" src={affiliation2} alt="" />
              <p className="text-center pt-2">
                Association of the
                <br />
                United States Army
              </p>
            </div>
            <div data-aos="zoom-in" className="description temp_logo_1">
              <img className="aff1" src={affiliation3} alt="" />
              <p className="text-center pt-2">
                U.S. Department of
                <br />
                Veteran Affairs
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CustomerAffiliation;
