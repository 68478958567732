import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import aboutBody from "../assect/images/about/about-body.webp";
import ContactUs from "../Components/ContactUs";

function Aboutus() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <section id="about_page_section">
      <div className="about_page menu_padding">
        <div className="hero_area">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column m-auto">
                <h1 className="header-1">
                  About <span>Us</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="body_section">
          <div className="container">
            <div className="row d-flex flex-column">
              <div data-aos="zoom-in" className="description">
                <p>
                  Enrich Group LLC is a global tech company delivering
                  <b> Cybersecurity</b> and{" "}
                  <b> Open Source Intelligence (OSINT) </b>
                  solutions, products, and services.
                  <br></br>
                  <br></br>
                  With 25 years of combined experience in tech we deliver
                  AI-driven customized solutions that cater to each client’s
                  specific requirements. We build strategic partnerships and
                  create a path forward to meet goals and thrive to become the
                  partner of choice by adopting incremental and continuous
                  improvement strategies. We utilize state-of-the-art
                  technologies and tested methodologies to achieve the best
                  results so our customers can experience the utmost benefits
                  while minimizing business operational impacts and service
                  disruption.
                </p>
              </div>
              <div className="img_sec">
                <img src={aboutBody} alt="" />
              </div>

              <div className="title">
                <h1 className="header-2">Vision</h1>
              </div>
              <hr className="_line" />
              <div data-aos="zoom-in" className="description">
                <p>
                  We aim to deliver the highest quality Cybersecurity, Open
                  Source Intelligence, information technology services and
                  business solutions.
                </p>
              </div>
              <div className="title">
                <h1 className="header-2">Mission</h1>
              </div>
              <hr className="_line" />
              <div data-aos="zoom-in" className="description">
                <p>
                  We are committed to providing our customers with superior
                  service and keeping their data and IT systems secure from
                  attacks. Our services assure our customers that they are in
                  reliable hands and quality products and services will be
                  delivered on time and budget.
                </p>
              </div>
            </div>
          </div>
          <ContactUs></ContactUs>
        </div>
      </div>
    </section>
  );
}

export default Aboutus;
