import React from 'react'

function ContactUs() {
    return (
        <div className="container _contact_text">
            {/* <div className="row d-flex justify-content-center flex-column">
                <div className="contact_text text-center">
                    <p>Our core beliefs are <br /> People / Process / Product</p>
                </div>
                <div className="contact_btn">
                    <HashLink to='/contact#contact_section'>Contact us</HashLink>
                </div>
            </div> */}
        </div>
    )
}

export default ContactUs
