import React from "react";

function training() {
  return (
    <section id="trining_page_section">
      <div className="training menu_padding">
        <div className="hero_area">
          <div className="container">
            <div className="row hero_title">
              <div className="title d-flex flex-column text-center">
                <h1 className="header-1 portfollio_hero_title">Training</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="body_section white_texture_bg body_section_phone">
          <div className="container pt-2rem pb-2rem">
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                Cybersecurity
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-center">
                <p className="description portfollio_desc_phone">
                  Custom Cyber Training to Build Job Skills
                </p>
                <p className="description portfollio_desc_phone">
                  Cybersecurity for Executives
                </p>
              </div>
            </div>

            <br></br>

            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                Open Source Intelligence
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-center">
                <p className="description portfollio_desc_phone">
                  Basic Open Source Intelligence (OSINT) Research, Analysis and
                  Tool Course
                </p>
                <p className="description portfollio_desc_phone">
                  Advanced Open Source Intelligence (OSINT) Research and
                  Analysis Course for All Source Analysis
                </p>
                <p className="description portfollio_desc_phone">
                  Introduction to OSINT Analysis for Executives
                </p>
              </div>
            </div>

            <br></br>
            <div className="row d-flex align-items-center">
              <h2 className="header-3 mr-2rem portfollio_header_phone">
                Academic Courses
              </h2>
            </div>

            <div className="row body_top">
              <div data-aos="zoom-in" className="col-md-12 text-center">
                <p className="description portfollio_desc_phone">
                  Develop custom university courses for cybersecurity
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default training;
