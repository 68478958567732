import React, { useEffect } from 'react'
import Aos from 'aos'
import "aos/dist/aos.css"
import './App.css';
import Section from './route/Section'
import { BrowserRouter as Router } from "react-router-dom";
import './assect/css/bootstrap.min.css'
import './assect/css/components.css';
import './assect/css/pages.css';
import './assect/css/responsive.css';
import Footer from './Components/Footer';
import Navbar from './Components/Navbar';


function App() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="App">
      <Router>
        <Navbar></Navbar>
        <Section></Section>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
