import React from "react";
import { Route } from "react-router-dom";
import Home from "../Pages/Home";
import About from "../Pages/Aboutus";
import Capability from "../Pages/Capability";
import Career from "../Pages/Career";
import Contact from "../Pages/Contact";
import Services from "../Pages/ServicesTraining";
import Partners from "../Pages/Partners";
import CustomerAffiliation from "../Pages/CustomerAffiliation";
import ee01 from "../Pages/ee01";
import et01 from "../Pages/et01";
import et02 from "../Pages/et02";
import et03 from "../Pages/et03";
import training from "../Pages/training";
import quicklink from "../Pages/quicklink";
import underconstruction from "../Pages/underconstruction";
import ee00 from "../Pages/ee00";

class Section extends React.Component {
  render() {
    return (
      <section>
        <Route path="/" component={Home} exact />
        <Route path="/about" component={About} exact />
        <Route path="/career" component={Career} exact />
        <Route path="/capability" component={Capability} exact />
        <Route path="/services" component={Services} exact />
        <Route path="/partners" component={Partners} exact />
        <Route path="/contact" component={Contact} exact />
        <Route
          path="/customeraffiliation"
          component={CustomerAffiliation}
          exact
        />
        <Route path="/ee01" component={ee01} exact />
        <Route path="/et01" component={et01} exact />
        <Route path="/et02" component={et02} exact />
        <Route path="/et03" component={et03} exact />
        <Route path="/ee00" component={ee00} exact />
        <Route path="/training" component={training} exact />
        <Route path="/quicklink" component={quicklink} exact />
        <Route path="/underconstruction" component={underconstruction} exact />
      </section>
    );
  }
}

export default Section;
